import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['picker'];

  connect() {
    $(this.pickerTargets).datetimepicker({
      locale: 'pt-br',
      autoclose: true,
    });

    document.addEventListener('click', this.closeOnClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.closeOnClickOutside.bind(this));
  }

  closeOnClickOutside(event) {
    if (!this.element.contains(event.target)) {
      $(this.pickerTargets).datetimepicker('hide');
    }
  }
}
