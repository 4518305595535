import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['checkbox'];

  connect() {
    if (this.checkboxTarget.querySelector('.collection-checkbox').checked) {
      this.update();
    }
  }

  toggleCheckbox(e) {
    const checkbox = this.checkboxTarget.querySelector('input[type=checkbox]');
    if (e.target.type !== 'checkbox' && e.target.tagName !== 'LABEL')
      checkbox.checked = !checkbox.checked;
    this.update();
  }

  update() {
    this.checkboxTarget.classList.toggle('selected');
  }
}
