import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { variant: String };

  onChangeAlternativeType(event) {
    const select = event.target;
    let options;
    let alternativeNumbersToShow = [];
    switch (select.value) {
      case '2':
        options = [
          ['C', 3],
          ['E', 5],
        ];
        alternativeNumbersToShow = [3, 5];
        break;
      case '4':
        options = [
          ['A', 1],
          ['B', 2],
          ['C', 3],
          ['D', 4],
        ];
        alternativeNumbersToShow = [1, 2, 3, 4];
        break;
      case '5':
        options = [
          ['A', 1],
          ['B', 2],
          ['C', 3],
          ['D', 4],
          ['E', 5],
        ];
        alternativeNumbersToShow = [1, 2, 3, 4, 5];
        break;
    }

    let selectCorrectAlternative;
    if (this.variantValue === 'question') {
      selectCorrectAlternative = document.getElementById(
        'questions_model_correct_alternative_number',
      );
    } else {
      selectCorrectAlternative =
        select.parentElement.parentElement.nextElementSibling.children[1].children[0];
    }

    if (options) {
      // Changes alternatives select
      selectCorrectAlternative.disabled = false;
      selectCorrectAlternative.innerHTML = ''; // removes all options from old select
      this.optionsToHtml(options).forEach((item, index) => {
        selectCorrectAlternative.options[index] = item;
      });
    } else {
      // Disable alternatives select
      selectCorrectAlternative.innerHTML = '';
      selectCorrectAlternative.disabled = true;
    }

    if (this.variantValue === 'question') {
      this.displayTargetAlternativeInputs(select, alternativeNumbersToShow);
    } else if (this.variantValue === 'exam') {
      this.updateStepFourQuestionInput({ target: selectCorrectAlternative });
    }
  }

  optionsToHtml(options) {
    let optionsHtml = [];
    options.forEach((item, index) => {
      optionsHtml[index + 1] = new Option(item[0], item[1]);
    });
    return optionsHtml;
  }

  // Update Review Exam Step Inputs
  updateStepFourQuestionInput(event) {
    // Get the index of the question
    const wrapper = event.target.closest('.nested-fields');
    const index = Array.from(wrapper.parentElement.children).indexOf(wrapper);

    // Get the target question row in step4
    const stepFour = document.getElementsByClassName('step-four');
    const stepFourArea = stepFour[0].getElementsByClassName('fields')[0];
    const targetInputRow = stepFourArea.children[index];

    // Find the pattern of the id in the current input that repeats in the target input
    const pattern = /(\D+)(\d+)(.*)/;
    const targetInputId = event.target.id.match(pattern)[3];

    // Find the target Input
    const targetInput = targetInputRow.querySelectorAll('[id*="' + targetInputId + '"]');

    targetInput[0].value = event.target.value;
    if (event.target.tagName.toLowerCase() === 'select') {
      const inputElement = $(event.target);
      $(targetInput).val(inputElement.val()).trigger('change');
    }
  }

  // Display and disable question alternative inputs dependending on alternatives to show
  displayTargetAlternativeInputs(select, alternativeNumbersToShow) {
    for (let i = 1; i <= 5; i++) {
      const alternative = document.getElementById(`alternative_${i}`);
      if (alternativeNumbersToShow.includes(i)) {
        alternative.classList.remove('d-none');
        alternative.querySelectorAll('textarea, input').forEach((children) => {
          children.disabled = false;
        });
      } else {
        alternative.classList.add('d-none');
        alternative.querySelectorAll('textarea, input').forEach((children) => {
          children.disabled = true;
        });
      }
    }

    const textGenreSelect = document.getElementById('questions_model_essays_text_genre_id');
    const divTextGenre = document.getElementById('div-text-genre');
    const divCorrectAlternative = document.getElementById('div-correct-alternative');
    switch (select.options[select.selectedIndex].text) {
      case 'Produção Textual':
        divTextGenre.classList.remove('d-none');
        divCorrectAlternative.classList.add('d-none');
        textGenreSelect.disabled = false;
        break;
      default:
        divTextGenre.classList.add('d-none');
        divCorrectAlternative.classList.remove('d-none');
        textGenreSelect.disabled = true;
        break;
    }
  }
}
