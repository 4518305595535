import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  filter(event) {
    event.preventDefault();

    const listParams = [
      ['title', $('#title').val()],
      ['school_subject_id', $('#subject').val()],
      ['id', $('#id').val()],
      ['created_at', $('#created_at').val()],
      ['abilities', $('#ability').val()],
      ['alternative_quantity', $('#quantity').val()],
      ['questions_repo', $('#question_repo').val()],
      ['level', $('#level').val()],
      ['only_my_questions', $('.check-box').prop('checked')],
    ];

    $.ajax({
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      url: `/questions/models?question${window.listParamsToQueryUrlQuestion(
        listParams,
      )}commit=Pesquisar`,
      remote: true,
      dataType: 'script',
      type: 'get',
    });
  }
}
