import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = {
    color: Array,
    data: Array,
    label: Array,
    id: Number,
  };
  connect() {
    let maxvalue = Math.max(...this.dataValue);
    maxvalue = maxvalue > 10 ? maxvalue + 5 : maxvalue + 2;
    const ctx = document.getElementById(`question-details-chart-${this.idValue}`);
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.labelValue,
        datasets: [
          {
            backgroundColor: this.colorValue,
            data: this.dataValue,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Contagem de respostas por alternativa',
            fontSize: 16,
          },
        },
        barThickness: 40,
        responsive: true,
        scales: {
          y: {
            max: maxvalue,
            min: 0,
          },
        },
      },
    });
  }
}
