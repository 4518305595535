import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

export default class extends Controller {
  static targets = ['selectedQuestions', 'availableQuestions'];
  static values = { qteQuestions: Number, action: String };

  initialize() {
    this.selectedQuestions = [];
    this.page = 1;
    this.pagesCount = 1;
  }

  connect() {
    if (this.actionValue === 'new') {
      this.loadQuestions(1);
    }
  }

  loadQuestions(page = 1) {
    const forms = $('.filter-repo-questions-form');

    const formData = { question: {}, page: page };
    forms.serializeArray().forEach((item) => {
      formData.question[item.name] = item.value;
    });

    const successFunction = (response, status, xhr) => {
      this.pagesCount = JSON.parse(xhr.getResponseHeader('pagy')).pages;
      Turbo.renderStreamMessage(response);
    };

    this.sendAjaxRequest(formData, '/exams/repo_questions', 'get', true, successFunction);
  }

  // Sortable JS Logic
  // ------------------------------------------------------------------------------------
  availableQuestionsTargetConnected(element) {
    if (screen.width >= 768) {
      this.questionsList = Sortable.create(element, {
        group: 'shared',
        onEnd: this.endQuestionList.bind(this),
      });
    }

    this.selectedQuestions.forEach((selectedQuestionId) => {
      const card = element.querySelector(`#question-${selectedQuestionId}`);
      if (card) {
        card.remove();
      }
    });

    element.addEventListener('scroll', () => {
      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight;
      const containerHeight = element.clientHeight;

      if (scrollTop + containerHeight >= scrollHeight && this.pagesCount > this.page) {
        this.loadQuestions(this.page + 1);
        this.page += 1;
      }
    });
  }

  selectedQuestionsTargetConnected(element) {
    if (screen.width >= 768) {
      this.questionsList = Sortable.create(element, {
        group: 'shared',
        onEnd: this.endSelectedQuestionList.bind(this),
      });
    }
  }

  // Drag and drop logic
  // ------------------------------------------------------------------------------------
  endQuestionList(event) {
    const questionId = event.item.children[0].dataset.id;
    // If was not moves between same question list
    if (event.from !== event.to) {
      this.selectedQuestions.insert(event.newIndex, questionId);
      this.qteQuestionsValue += 1;
      event.item.outerHTML = this.transformQuestionDiv(event.item, 'selected').outerHTML;
    }
  }

  endSelectedQuestionList(event) {
    const questionId = event.item.children[0].dataset.id;
    // Removes
    this.selectedQuestions = this.selectedQuestions.filter((item) => item !== questionId);
    if (event.from === event.to) {
      // Change order
      this.selectedQuestions.insert(event.newIndex, questionId);
    } else {
      this.qteQuestionsValue -= 1;
      event.item.outerHTML = this.transformQuestionDiv(event.item, 'available').outerHTML;
    }
  }

  // Moves logic with click btn
  // ------------------------------------------------------------------------------------
  addQuestion(event) {
    const oldQuestionDiv = event.target.closest('.question-card-component');

    // If question is not included
    if (!this.selectedQuestions.includes(oldQuestionDiv.dataset.id)) {
      // Adds id in selectedQuestions

      this.selectedQuestions.push(oldQuestionDiv.dataset.id);

      // Append html in selectedQuestions col
      // this.selectedQuestionsTarget.innerHTML += oldQuestionDiv.outerHTML;
      this.selectedQuestionsTarget.innerHTML += this.transformQuestionDiv(
        oldQuestionDiv.parentElement,
        'selected',
      ).outerHTML;

      // Removes from question col
      oldQuestionDiv.parentElement.remove();

      // Add Questions qte
      this.qteQuestionsValue += 1;
    }
  }

  // 'Unmove' logic with click
  removeQuestion(event) {
    const questionId = event.target.closest('.question-card-component').dataset.id;

    // If question is included
    if (this.selectedQuestions.includes(questionId)) {
      // removes id from selectedQuestions
      this.selectedQuestions.splice(this.selectedQuestions.indexOf(questionId), 1);

      const oldQuestionDiv = event.target.closest('.question-card-component');

      // Append html in availableQuestions col
      this.availableQuestionsTarget.innerHTML =
        this.transformQuestionDiv(oldQuestionDiv.parentElement, 'available').outerHTML +
        this.availableQuestionsTarget.innerHTML;

      // Removes from selected question col
      event.target.closest('.question-card-component').parentElement.remove();

      // Subtract questions qte
      this.qteQuestionsValue -= 1;
    }
  }

  // Update questions quantity
  qteQuestionsValueChanged() {
    const stepFour = document.getElementsByClassName('step-four')[0];

    if (stepFour) {
      stepFour.querySelector(
        '.questions-counter',
      ).innerText = `${this.qteQuestionsValue} Questões inseridas`;

      let reviewQuestions = stepFour.getElementsByClassName('review-questions-compact')[0];
      const questionsInputs = stepFour.getElementsByClassName('review-question-input');

      // Adds or removes questions on the review area
      if (this.qteQuestionsValue > questionsInputs.length - 1) {
        // A question was added
        const newQuestion = reviewQuestions.children[0].cloneNode(true);
        newQuestion.classList.remove('d-none');
        newQuestion.querySelector('input').value = `Questão ${this.qteQuestionsValue}`;

        reviewQuestions.appendChild(newQuestion);
      } else if (this.qteQuestionsValue < questionsInputs.length - 1) {
        // A question was removed
        reviewQuestions.removeChild(reviewQuestions.lastElementChild);
      }
    }
  }

  // ----------------------------------------------------------------------------------------

  transformQuestionDiv(questionDiv, type) {
    let newCardDiv = questionDiv.cloneNode(true);

    if (type === 'available') {
      newCardDiv.querySelector('.question-card-button-add').classList.toggle('d-none');
      newCardDiv.querySelector('.question-card-button-remove').classList.toggle('d-none');
    } else {
      newCardDiv.querySelector('.question-card-button-add').classList.toggle('d-none');
      newCardDiv.querySelector('.question-card-button-remove').classList.toggle('d-none');
    }
    return newCardDiv;
  }

  // Filter
  // ------------------------------------------------------------------------------------
  filterQuestions(event) {
    event.preventDefault();
    this.page = 1;
    this.loadQuestions(1);
  }

  clearFilters(event) {
    event.preventDefault();

    var form = document.getElementsByClassName('filter-repo-questions-form')[0];
    var inputs = form.getElementsByTagName('input');
    var selects = form.getElementsByTagName('select');

    inputs.forEach((input) => {
      input.value = '';
    });

    selects.forEach((select) => {
      $(select).val(null).trigger('change');
    });
  }

  // Hide/Show questions on review step
  showQuestions() {
    document.getElementsByClassName('show-questions')[0].classList.add('d-none');
    document.getElementsByClassName('hide-questions')[0].classList.remove('d-none');

    document.getElementsByClassName('review-questions-compact')[0].classList.add('d-none');
    document.getElementsByClassName('review-questions-expanded')[0].classList.remove('d-none');

    if (this.actionValue === 'new') {
      const successFunction = (response) => {
        Turbo.renderStreamMessage(response);
      };
      this.sendAjaxRequest(
        { questions: this.selectedQuestions },
        '/exams/repo_review_questions',
        'post',
        true,
        successFunction,
      );
    }
  }

  hideQuestions() {
    document.getElementsByClassName('hide-questions')[0].classList.add('d-none');
    document.getElementsByClassName('show-questions')[0].classList.remove('d-none');

    document.getElementsByClassName('review-questions-expanded')[0].classList.add('d-none');
    document.getElementsByClassName('review-questions-compact')[0].classList.remove('d-none');
  }

  submit() {
    const examNameInput = document.getElementById('exams_model_name');
    const examSchoolSubjectIdInput = document.getElementById('exams_model_school_subject_id');
    const examYearInput = document.getElementById('exams_model_year');
    const examBatchInput = document.getElementById('exams_model_exams_batch_ids');
    const examTemplateInput = document.getElementById('exams_model_exams_template_id');

    if (this.selectedQuestions.length < 1) {
      alert('Insira ao menos uma questão na atividade.');
      return;
    }

    if (examNameInput.value === '' || examNameInput.value === null) {
      alert('Insira um nome para a atividade.');
      examNameInput.focus();
      return;
    }

    const questionsAttrs = this.selectedQuestions.map((questionId, index) => {
      return { number: index + 1, weight: 1, questions_models_id: questionId };
    });

    const examAttrs = {
      exams_model: {
        name: examNameInput.value,
        school_subject_id: examSchoolSubjectIdInput.value,
        year: examYearInput.value,
        exams_batch_ids: examBatchInput ? examBatchInput.value : null,
        exams_template_id: examTemplateInput.value,
        type: 'Exams::RegularExam',
        questions_attributes: questionsAttrs,
      },
    };

    this.sendAjaxRequest(JSON.stringify(examAttrs), '/exams/create', 'post', false, () => {});
  }

  sendAjaxRequest(data, url, method = 'get', turbo = false, successFunction) {
    $.ajax({
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        if (turbo) {
          xhr.setRequestHeader(
            'Accept',
            'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
          );
        }
      },
      url: url,
      type: method,
      data: data,
      contentType: turbo ? 'application/x-www-form-urlencoded; charset=UTF-8' : 'application/json',
      success: successFunction,
    });
  }
}
