import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  connect() {
    // Removes sort icon
    const sortIcons = document.getElementsByClassName('hide-sortable');

    for (let i = 0; i < sortIcons.length; i++) {
      let children = sortIcons[i].children[0];
      if (children) {
        sortIcons[i].children[0].classList.remove('sortable');
      }
    }
  }
}
