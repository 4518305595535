import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  connect() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        boundary: 'window',
        template:
          '<div class="tooltip tooltip-message" role="tooltip"><div class="arrow"></div><div class="tooltip-inner tooltip-message-css"></div></div>',
      });
    });
  }
}
