const alertMessage = (message, type) => {
  $.ajax({
    beforeSend: (xhr) => {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      xhr.setRequestHeader(
        'Accept',
        'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
      );
    },
    url: '/turbo_flash',
    type: 'post',
    data: { type: type, message: message },
    dataType: 'script',
    success: function (response) {
      Turbo.renderStreamMessage(response);
    },
  });
};

export default alertMessage;
