import { Controller } from '@hotwired/stimulus';
import Tooltip from 'bootstrap/js/dist/tooltip';

export default class extends Controller {
  connect() {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    popoverTriggerList.map(function (element) {
      return new Tooltip(element);
    });
  }
}
