import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  connect() {
    const table = $('#presence-table');
    table.bootstrapTable('destroy').bootstrapTable({
      classes: 'table ',
    });
  }
}
