import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['scheduleTable'];
  static values = { editUrl: String, deleteUrl: String };

  initialize() {
    this.editMode = false;
    this.isDeleting = false;
    this.allSelected = false;
    this.selectedElements = [];
    this.totalElements = 0;
  }

  scheduleTableTargetConnected() {
    this.editMode ? this.addEditColumn() : this.removeEditColumn();
    this.checkSelectedBoxes();
    this.totalElements = document.querySelector('.schedule-count').innerHTML;
    if (this.allSelected) this.checkAllBoxes(true);
  }

  toggleEdit(event) {
    this.isDeleting = event.params.mode === 'delete';
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.toggleColorSelectedElements(false);
      this.clearAll();
    }
    this.editMode ? this.addEditColumn() : this.removeEditColumn();
    this.toggleBottomBar();
    this.toggleButton();
  }

  addEditColumn() {
    const table = document.querySelector('table');

    // Create a new th for the header with a checkbox
    const newHeader = document.createElement('th');
    const headerCheckbox = document.createElement('input');
    headerCheckbox.type = 'checkbox';
    newHeader.appendChild(headerCheckbox);
    headerCheckbox.setAttribute(
      'data-action',
      'change->exams-batches--edit-schedule#toggleAllElements',
    );
    table.rows[0].insertBefore(newHeader, table.rows[0].cells[0]);

    // Iterate through the body rows
    for (let i = 1; i < table.rows.length; i++) {
      const newCell = document.createElement('td'); // Create a new table cell

      // Create a checkbox input element and append it to the new cell
      const checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      checkbox.setAttribute('data-action', 'change->exams-batches--edit-schedule#toggleElement');
      newCell.appendChild(checkbox);

      // Insert the new cell as the first cell in the current row
      const bodyRow = table.rows[i];
      bodyRow.insertBefore(newCell, bodyRow.cells[0]);
    }
  }

  removeEditColumn() {
    // Get the table element by its id
    const table = document.querySelector('table');

    // Iterate through the rows and remove the first cell in each row
    for (var i = 0; i < table.rows.length; i++) {
      const firstCell = table.rows[i].cells[0];
      const hasInput = firstCell.querySelector('input');
      if (hasInput) {
        table.rows[i].deleteCell(0);
      }
    }
  }

  toggleElement(e) {
    const checked = e.target.checked;
    const id = e.target.parentElement.parentElement.id.match(/(\d+)/)[0];
    e.target.parentElement.parentElement.classList.toggle('selected-table-row');

    if (checked) {
      this.selectedElements.push(id);
    } else {
      this.selectedElements = this.selectedElements.filter(function (item) {
        return item !== id;
      });
    }

    // Empty the selected elements if the user unchecks an element when all elements were checked
    if (this.allSelected) {
      this.checkAllBoxes(false);
      this.allSelected = false;
      this.selectedElements = [];
    }

    this.changeSelectedCount();
  }

  toggleAllElements(e) {
    const checked = e.target.checked;

    // Clear the selected elements
    this.allSelected = checked;
    this.selectedElements = [];

    this.checkAllBoxes(checked);
    this.changeSelectedCount();
  }

  clearAll() {
    this.allSelected = false;
    this.selectedElements = [];
    this.changeSelectedCount();
  }

  checkAllBoxes(checked) {
    // Checks or unchecks all elements
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) {
      if (checked) {
        checkboxes[i].parentElement.parentElement.classList.add('selected-table-row');
      } else {
        checkboxes[i].parentElement.parentElement.classList.remove('selected-table-row');
      }
      checkboxes[i].checked = checked;
    }
  }

  checkSelectedBoxes() {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (var i = 1; i < checkboxes.length; i++) {
      const id = checkboxes[i].parentElement.parentElement.id.match(/(\d+)/)[0];
      var foundElement = this.selectedElements.find((item) => item === id);
      if (foundElement) {
        checkboxes[i].parentElement.parentElement.classList.add('selected-table-row');
        checkboxes[i].checked = true;
      }
    }
  }

  toggleBottomBar() {
    document.querySelector('.bottom-schedule-info').classList.toggle('d-none');
    document.querySelector('.bottom-schedule-info').classList.toggle('d-flex');
  }

  toggleButton() {
    document.querySelectorAll('.enable-button').forEach((element) => {
      element.classList.toggle('d-none');
    });
    document.querySelectorAll('.disable-button').forEach((element) => {
      element.classList.toggle('d-none');
    });
  }

  toggleColorSelectedElements(checked) {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (var i = 1; i < checkboxes.length; i++) {
      if (this.allSelected) {
        checkboxes[i].parentElement.parentElement.classList.toggle('selected-table-row');
      } else {
        const id = checkboxes[i].parentElement.parentElement.id.match(/(\d+)/)[0];
        var foundElement = this.selectedElements.find((item) => item === id);
        if (foundElement) {
          checkboxes[i].checked = checked;
          checkboxes[i].parentElement.parentElement.classList.toggle('selected-table-row');
        }
      }
    }
  }

  changeSelectedCount() {
    let count;
    if (this.allSelected) {
      count = this.totalElements;
    } else {
      count = this.selectedElements.length;
    }
    document.querySelector(
      '.bottom-schedule-info .sub-text',
    ).textContent = `${count} Agendamentos Selecionados`;
  }

  showDeleteModal() {
    let modal = document.getElementById('confirmDeleteId');
    $(modal).modal('show');
  }

  runAction() {
    if (this.isDeleting) {
      this.deleteSchedule();
    } else {
      this.editSchedule();
    }
  }

  deleteSchedule() {
    let queryString = '';
    if (this.allSelected) {
      const county_id = document.querySelector('#county_id').value;
      const exams_model_id = document.querySelector('#exams_model_id').value;
      queryString = `?all=true&county_id=${county_id}&exams_model_id=${exams_model_id}`;
    } else {
      queryString =
        '?all=false&scheduled_exams_ids=' +
        encodeURIComponent(JSON.stringify(this.selectedElements));
    }

    let url = this.deleteUrlValue + queryString;

    let modal = document.getElementById('confirmDeleteId');
    let form = modal.querySelector('form');
    form.action = url;
    this.showDeleteModal();
  }

  editSchedule() {
    let queryString = '';
    const county_id_element = document.querySelector('#county_id');
    const exams_model_id_element = document.querySelector('#exams_model_id');

    let county_id = county_id_element ? county_id_element.value : '';
    let exams_model_id = exams_model_id_element ? exams_model_id_element.value : '';

    if (this.allSelected) {
      queryString = `?all=true&county_id=${county_id}&exams_model_id=${exams_model_id}`;
    } else {
      queryString = '?all=false&ids=' + encodeURIComponent(JSON.stringify(this.selectedElements));
    }
    Turbo.visit(this.editUrlValue + queryString);
  }
}
