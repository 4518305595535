import { Controller as BaseController } from '@hotwired/stimulus';

const defaultProps = {
  lang: 'pt-BR',
  inheritPlaceholder: true,
  lineHeights: ['0.2', '0.3', '0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '2.0', '3.0'],
  toolbar: [
    ['style', ['style']],
    ['fontname', ['fontname']],
    ['fontsize', ['fontsize']],
    ['fontStyle', ['bold', 'underline', 'color', 'clear']],
    ['para', ['height', 'ul', 'ol', 'paragraph']],
    ['table', ['table']],
    ['insert', ['link', 'picture']],
    ['view', ['fullscreen', 'codeview', 'help']],
    ['extra', ['math']],
  ],
};

const miniToolBar = {
  lang: 'pt-BR',
  inheritPlaceholder: true,
  lineHeights: ['0.2', '0.3', '0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '2.0', '3.0'],
  toolbar: [
    ['style', ['style']],
    ['fontname', ['fontname']],
    ['fontsize', ['fontsize']],
    ['fontStyle', ['bold', 'underline', 'color', 'clear']],
    ['para', ['height', 'ul', 'ol', 'paragraph']],
  ],
};

export default class Controller extends BaseController {
  static targets = ['summernote'];
  static values = {
    dialogsInBody: { type: Boolean, default: true },
    responseId: String,
    updatePath: String,
    questionId: String,
    enableCallback: { type: Boolean, default: false },
    miniStudentToolBar: { type: Boolean, default: false },
  };

  connect() {
    const toolbarOptions = this.miniStudentToolBarValue ? miniToolBar : defaultProps;

    $(this.summernoteTarget).summernote({
      ...toolbarOptions,
      dialogsInBody: this.dialogsInBodyValue,
      height: 300,
      callbacks: {
        onImageUpload: (files) => {
          window.summernoteSendFile(files[0], $(this.summernoteTarget));
        },
        onMediaDelete: (target) => {
          const uploadID = target['0'].id.split('-').slice(-1)[0];
          if (uploadID) {
            window.summernoteDeleteFile(uploadID);
          }
          target.remove();
        },
      },
    });

    const containerChildren =
      this.summernoteTarget.parentElement.getElementsByClassName('note-editor');
    if (containerChildren.length > 1) {
      containerChildren[1].remove();
    }
    this.delegate('summernote.blur', ['event']);
  }

  //https://gist.github.com/kaspermeyer/7fe28bb7c55c2810e7b5f3d5e67c1a44
  delegate(eventName, parameters) {
    const handler = (...args) => {
      const data = {};
      parameters.forEach((name, i) => (data[name] = args[i]));
      const delegatedEvent = new CustomEvent('jquery:' + eventName, {
        bubbles: true,
        cancelable: true,
        detail: data,
      });
      data.event.target.dispatchEvent(delegatedEvent);
    };
    $(this.summernoteTarget).on(eventName, handler);
  }
}
