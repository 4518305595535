import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = { schoolClassesData: Object, correctionType: String, reportType: String };

  connect() {
    const school_classes = this.schoolClassesDataValue;
    delete school_classes.sum;
    const correction_type = this.correctionTypeValue;
    const report_type = this.reportTypeValue;

    const school_classes_names = [];

    const situation_counter = {
      advanced: [],
      adequate: [],
      basic: [],
      below_basic: [],
    };

    Object.keys(school_classes).forEach((school_class_key, i) => {
      if (report_type === 'school_classes_report') {
        school_classes_names.push(school_classes[school_class_key].school_class.name);
      } else {
        school_classes_names.push(school_classes[school_class_key].school.name);
      }

      const situations =
        correction_type === 'tri'
          ? school_classes[school_class_key].situations.tri
          : school_classes[school_class_key].situations.normal;

      situation_counter.advanced[i] = situations.advanced;
      situation_counter.adequate[i] = situations.adequate;
      situation_counter.basic[i] = situations.basic;
      situation_counter.below_basic[i] = situations.below_basic;
    });

    const isMobile = screen.width < 640;

    const ctx = document.getElementById('class-comparison-chart').getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: school_classes_names,
        datasets: [
          {
            data: situation_counter.below_basic,
            label: 'Abx. do Básico',
            borderColor: '#D9534F',
            backgroundColor: '#D9534F',
            fill: false,
          },
          {
            data: situation_counter.basic,
            label: 'Básico',
            borderColor: '#F2C671',
            backgroundColor: '#F2C671',
            fill: false,
          },
          {
            data: situation_counter.adequate,
            label: 'Adequado',
            borderColor: '#87D187',
            backgroundColor: '#87D187',
            fill: false,
          },
          {
            data: situation_counter.advanced,
            label: 'Avançado',
            borderColor: '#5495CD',
            backgroundColor: '#5495CD',
            fill: false,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            labels: {
              boxWidth: 12,
            },
            position: 'bottom',
          },
        },
        scales: {
          y: {
            ticks: {
              precision: 0,
              font: {
                size: 15,
              },
            },
            grid: {
              display: false,
            },
            title: {
              text: 'Número de Estudantes',
              display: true,
              padding: 20,
              font: {
                size: 14,
              },
            },
          },
          x: {
            grid: {
              borderDash: [8, 8],
            },
            ticks: {
              font: {
                size: 15,
              },
              display: !isMobile,
            },
            offset: true,
          },
        },
        elements: {
          point: {
            radius: 4,
            hitRadius: 6,
            hoverRadius: 10,
          },
          line: {
            tension: 0.5,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
      },
    });
  }
}
