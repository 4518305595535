import { Controller as BaseController } from '@hotwired/stimulus';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class Controller extends BaseController {
  static values = {
    belowBasic: Number,
    basic: Number,
    adequate: Number,
    advanced: Number,
    chartId: String,
  };
  connect() {
    const data = [this.belowBasicValue, this.basicValue, this.adequateValue, this.advancedValue];
    const dataSum = data.reduce((partialSum, a) => partialSum + a, 0);

    const isMobile = screen.width < 640;

    const ctx = document.getElementById(this.chartIdValue).getContext('2d');
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Abx. do Básico', 'Básico', 'Adequado', 'Avançado'],
        datasets: [
          {
            data: data,
            backgroundColor: ['#D9534F', '#F2C671', '#87D187', '#5495CD'],
            borderWidth: 1,
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        aspectRatio: isMobile ? 1.2 : 640 / 195,
        plugins: {
          legend: {
            labels: {
              boxWidth: 15,
              font: {
                size: '15px',
              },
              generateLabels: (chart) => {
                return chart.data.labels.map((label, index) => ({
                  text: label,
                  strokeStyle: chart.data.datasets[0].backgroundColor[index],
                  fillStyle: chart.data.datasets[0].backgroundColor[index],
                }));
              },
            },
            position: 'bottom',
          },
          datalabels: {
            labels: {
              value: {
                color: ['#D9534F', '#F2C671', '#87D187', '#5495CD'],
                anchor: 'end',
                align: 'top',
                offset: 10,
                font: {
                  weight: 'bold',
                  size: '22px',
                },
              },
              name: {
                color: ['#FDCBCB', '#FFF8C6', '#DCF3DF', '#DCEFFE'],
                offset: 10,
                font: {
                  weight: 'bold',
                  size: isMobile ? '14px' : '14px',
                },
                formatter: function (value, ctx) {
                  return (ctx.chart.data.datasets[0].data[ctx.dataIndex] * 100) / dataSum == 100
                    ? `100%`
                    : `${((ctx.chart.data.datasets[0].data[ctx.dataIndex] * 100) / dataSum).toFixed(
                        2,
                      )}%`;
                },
              },
            },
          },
        },
        barPercentage: 0.6,
        scales: {
          y: {
            max: Math.round(Math.max.apply(null, data) * 1.3),
            ticks: {
              precision: 0,
              font: {
                size: 15,
              },
            },
            grid: {
              borderDash: [8, 8],
            },
            beginAtZero: true,
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
      },
    });
  }
}
