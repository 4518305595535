// app/javascript/controllers/form_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  showActiveFilter(mediaTypeClicker) {
    const icon = $(`#icon_${mediaTypeClicker}`);
    icon.toggleClass('icon-active');
  }

  submit(event) {
    this.showActiveFilter(event.target.id);
    this.formTarget.requestSubmit();
  }
}
