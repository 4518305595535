import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  submit(event) {
    if ($('#questions_model_question_content_attributes_description').summernote('isEmpty')) {
      event.preventDefault();
      alert('A descrição da questão não pode estar vazia!');
    }
  }
}
