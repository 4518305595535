import { Controller } from '@hotwired/stimulus';
import alertMessage from '../modules/error';
// needs this line in view <%= render 'layouts/adobe_pdf_script' %>

export default class extends Controller {
  static values = {
    clientId: String,
    filename: String,
    url: String,
    render: Boolean,
    divId: String,
    renderError: { type: Boolean, default: false },
    showAnnotationTools: { type: Boolean, default: true },
  };

  static targets = ['spinner'];

  initialize() {
    this.displayFunction = this.displayPdfWithAdobeDCView.bind(this);
    this.displayErrorFunction = null;
  }

  connect() {
    if (this.renderValue) {
      if (window.AdobeDC) {
        this.displayPDF(this.urlValue, this.filenameValue);
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', this.displayFunction);
      }

      if (this.renderErrorValue) {
        this.displayPdfLoadError();
      }
    }
  }

  disconnect() {
    document.removeEventListener('adobe_dc_view_sdk.ready', this.displayFunction);
  }

  displayPdfLoadError() {
    this.displayErrorFunction = setTimeout(() => {
      alertMessage('Erro ao carregar PDF, verifique a sua conexão.', 'error');
    }, 10000);
  }

  displayPdfWithAdobeDCView() {
    this.displayPDF(this.urlValue, this.filenameValue);
  }

  previewFile(event) {
    this.displayPDF(
      event.target.dataset.url,
      event.target.dataset.filename,
      true,
      event.target.dataset.divid || 'adobe-dc-view',
    );
  }

  displayPDF(url, filename, embed = false, divId = 'adobe-dc-view') {
    if (this.renderErrorValue) {
      clearTimeout(this.displayErrorFunction);
    }

    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add('d-none');
      this.spinnerTarget.classList.remove('d-flex');
    }

    const config = {
      showAnnotationTools: this.showAnnotationToolsValue,
      showBookmarks: false,
      showFullScreen: true,
      ...(embed && { embedMode: 'LIGHT_BOX' }),
    };

    let adobeDCViewConfig = {
      clientId: this.clientIdValue,
      locale: 'pt-BR',
    };

    if (!embed) {
      adobeDCViewConfig.divId = this.divIdValue || divId;
    }

    // eslint-disable-next-line
    const adobeDCView = new AdobeDC.View(adobeDCViewConfig);
    adobeDCView.previewFile(
      {
        content: { location: { url: url } },
        metaData: { fileName: filename },
      },
      config,
    );
  }
}
