$.jMaskGlobals.watchDataMask = true;

// Notification flash alert closes after some time
$(document).on('turbo:load', function () {
  $('.alert')
    .fadeTo(5000, 200)
    .slideUp(500, function () {
      $(this).alert('close');
    });

  // TODO: All selects that uses select 2 should be replaced by SelectBoxComponent
  // Setup any select2 plugin on page
  $('.select2-manual').select2({
    allowClear: true,
    placeholder: '',
    width: $(this).parent().width,
  });
});

$(document).on('turbo:load', function () {
  $('.date').mask('11/11/1111');
  $('.time').mask('00:00:00');
  $('.date_time').mask('00/00/0000 00:00:00');
  $('.cep').mask('00000-000');
  $('.cnpj').mask('00.000.000/0000-00');
  $('.phone').mask('00000-0000');
  $('.telephone').mask('00000-0000');
  $('.phone_with_ddd').mask('(00) 00000-0000');
  $('.cpf').mask('000.000.000-00');
  $('.money').mask('000.000.000.000.000,00', { reverse: true });
  $('.score').mask('00.0', { reverse: true });
});

// Dashboard Select
$(document).on('turbo:load', function () {
  $('select.counties').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Filtrar municípios',
    countSelectedText: (n) => n + ' municípios selecionadas',
  });
  $('select.schools').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 3',
    noneSelectedText: 'Filtrar escolas',
    countSelectedText: (n) => n + ' escolas selecionadas',
  });
  $('select.school_classes').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Filtrar turmas',
    countSelectedText: (n) => n + ' turmas selecionadas',
  });
  $('select.students').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher alunos',
    countSelectedText: (n) => n + ' alunos selecionados',
  });
  $('select.years').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher ano',
    countSelectedText: (n) => n + ' anos selecionados',
  });
  $('select.subjects').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher matérias',
    countSelectedText: (n) => n + ' matérias selecionadas',
  });
  $('select.number_of_alternatives').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher número de alternativas',
    countSelectedText: (n) => n + ' número de alternativas selecionados',
  });
  $('select.books').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher Livros',
    countSelectedText: (n) => n + ' Livros selecionados',
  });
  $('select.videos').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher vídeos',
    countSelectedText: (n) => n + ' Vídeos selecionados',
  });
  $('select.audios').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher áudios',
    countSelectedText: (n) => n + ' Áudios selecionados',
  });
  $('select.presentations').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher apresentações',
    countSelectedText: (n) => n + 'Apresentações selecionados',
  });
  $('select.games').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher jogos',
    countSelectedText: (n) => n + 'Jogos selecionados',
  });
  $('select.counties2').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher Municípios',
    countSelectedText: (n) => n + ' Municípios selecionados',
  });
  $('select.companies').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher empresas',
    countSelectedText: (n) => n + ' Empresas selecionados',
  });
  $('select.question-repo').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher Bancos de Questões',
    countSelectedText: (n) => n + ' Bancos de Questões selecionados',
  });
  $('select.abilities').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher Habilidades',
    countSelectedText: (n) => n + ' Habilidades selecionadas',
  });
  $('select.exam').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Escolher atividade',
    countSelectedText: (n) => n + ' Atividade selecionadas',
  });
  $('select.users').selectpicker({
    selectAllText: 'Todos',
    deselectAllText: 'Nenhum',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Filtrar usuários',
    countSelectedText: (n) => n + ' usuários selecionados',
  });
  $('select.school_classes_abilities').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Selecionar habilidades',
    countSelectedText: (n) => n + ' habilidades selecionadas',
  });
  $('select.correction_method').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Selecionar habilidades',
    countSelectedText: (n) => n + ' habilidades selecionadas',
  });
  $('select.exam_names').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Selecionar nome da atividade',
  });
  $('select.years_select').selectpicker({
    selectAllText: 'Todas',
    deselectAllText: 'Nenhuma',
    selectedTextFormat: 'count > 4',
    noneSelectedText: 'Selecionar habilidades',
    countSelectedText: (n) => n + ' habilidades selecionadas',
  });
});

// Clear turbo cache
$(document).on('turbo:before-cache', function () {
  const $picker = $('.selectpicker');
  const picker = $picker.data('selectpicker');
  if (picker) {
    picker.destroy();
    $picker.addClass('selectpicker');
  }
});

let autoGrowTextarea = (el) => {
  if (el.value) {
    el.style.height = '20px';
    el.style.height = el.scrollHeight + 'px';
  } else {
    el.style.height = '35px';
  }
};

window.autoGrowTextarea = autoGrowTextarea;

function beforePrint() {
  for (const id in Chart.instances) {
    Chart.instances[id].resize();
  }
}

if (window.matchMedia) {
  let mediaQueryList = window.matchMedia('print');
  mediaQueryList.addListener((mql) => {
    if (mql.matches) {
      beforePrint();
    }
  });
}

window.onbeforeprint = beforePrint;

$(document).on('turbo:load', function () {
  $('#upload-files').change(function () {
    this.parentNode.setAttribute('data-text', `Arquivo inserido: ${this.files[0].name}`);
  });
});

// Handles turbo bug with lazy loading
// https://github.com/hotwired/turbo/issues/886
document.addEventListener('turbo:load', () => {
  document
    .querySelectorAll('turbo-frame[loading="lazy"][complete]')
    .forEach((frame) => frame.removeAttribute('loading'));
});
