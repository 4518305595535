import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  changeModalStateTrue() {
    localStorage.setItem('showModal', 'true');
  }
  changeModalStateFalse() {
    localStorage.setItem('showModal', 'false');
  }
}
