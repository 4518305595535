import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    examId: Number,
    filterUrl: String,
  };

  connect() {
    let select_county = $('#counties_select');
    let select_school = $('#schools_select');

    $(select_county).on('change', () => this.sync_counties(this.examIdValue, this.filterUrlValue));

    $(select_school).on('change', () => this.sync_schools(this.examIdValue, this.filterUrlValue));
  }

  sync_counties(examId, filterUrl) {
    let select_county = $('#counties_select');
    let schools_select = $('#schools_select');
    let school_classes_select = $('#school_classes_select');
    $.ajax({
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      url: filterUrl,
      type: 'post',
      data: { county: select_county.val(), examId: examId },
      success: (data) => {
        let school_classes = data.selected_school_classes;
        let schools = data.selected_schools;

        school_classes_select.empty();
        schools_select.empty();

        school_classes.map((school_class) =>
          school_classes_select.append(
            '<option value=' + school_class.id + '>' + school_class.name + '</option>',
          ),
        );
        schools.map((school) =>
          schools_select.append('<option value=' + school.id + '>' + school.name + '</option>'),
        );

        school_classes_select.selectpicker('refresh');
        schools_select.selectpicker('refresh');
      },
    });
  }

  sync_schools(examId, filterUrl) {
    let schools_select = $('#schools_select');
    let school_classes_select = $('#school_classes_select');

    $.ajax({
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      url: filterUrl,
      type: 'post',
      data: { school: schools_select.val(), examId: examId },
      success: function (data) {
        let school_classes = data.selected_school_classes;

        school_classes_select.empty();

        school_classes.map((school_class) =>
          school_classes_select.append(
            '<option value=' + school_class.id + '>' + school_class.name + '</option>',
          ),
        );
        school_classes_select.selectpicker('refresh');
      },
    });
  }
}
