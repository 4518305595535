import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { counter: Number };

  stepTwo() {
    let thirdStep = document.querySelector('.step-three');
    if (thirdStep && thirdStep.classList.contains('d-none') && !this.validFields()) return;
    document.querySelector('.step-two').classList.remove('d-none');
    document.querySelector('.step-three').classList.add('d-none');
    document.querySelector('.step-four').classList.add('d-none');
  }

  stepThree() {
    let fourthStep = document.querySelector('.step-four');
    if (fourthStep && fourthStep.classList.contains('d-none') && !this.validFields()) return;
    document.querySelector('.step-two').classList.add('d-none');
    document.querySelector('.step-three').classList.remove('d-none');
    document.querySelector('.step-four').classList.add('d-none');
  }

  stepFour() {
    if (!this.validFields()) return;
    document.querySelector('.step-two').classList.add('d-none');
    document.querySelector('.step-three').classList.add('d-none');
    document.querySelector('.step-four').classList.remove('d-none');
  }

  updateStepFourInput(event) {
    const inputId = event.target.id;
    const targetInputId = inputId.split('exams_model_')[1];
    const step4 = document.getElementsByClassName('step-four')[0];
    const targetInput = step4.querySelectorAll('[id^="' + targetInputId + '"]')[0];
    targetInput.value = event.target.value;
    if (event.target.tagName.toLowerCase() === 'select') {
      $(targetInput).val(event.target.value).trigger('change');
    }
  }

  updateStepFourFile(event) {
    const targetInput = document.getElementById('selected_file');
    if (event.target.files.length > 0) {
      targetInput.value = event.target.files[0].name;
    } else {
      targetInput.value = '';
    }
  }

  openWindowConfirmation() {
    $('.confirmation-window').removeClass('d-none');
    $('.confirmation-window-message').removeClass('d-none');
  }

  closeWindowConfirmation() {
    $('.confirmation-window').addClass('d-none');
    $('.confirmation-window-message').addClass('d-none');
  }

  validFields() {
    let nameInput = document.querySelector('#exams_model_name');
    let subjectInput = document.querySelector('#exams_model_school_subject_id');
    let fileInput = document.querySelector('#upload-files');
    let correctQuestions = document.querySelectorAll('.nested-fields');
    let stepTwo = document.querySelector('.step-two');
    let stepThree = document.querySelector('.step-three');

    if (
      stepTwo &&
      !stepTwo.classList.contains('d-none') &&
      (!nameInput.checkValidity() || !subjectInput.checkValidity())
    ) {
      subjectInput.reportValidity();
      nameInput.reportValidity();
      return false;
    } else if (stepThree && !stepThree.classList.contains('d-none')) {
      if (fileInput && !fileInput.checkValidity()) {
        alert('Insira o pdf da Atividade');
        return false;
      }

      if (correctQuestions.length > 0) {
        let validAnswers = true;
        correctQuestions.forEach((element) => {
          if (!element.children.item(2).querySelector('select').checkValidity()) {
            alert('Insira os gabaritos das questões');
            validAnswers = false;
          }
        });

        return validAnswers;
      }
    }
    return true;
  }
}
