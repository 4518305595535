import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['editForm', 'commentBody'];

  connect() {}

  toggleEdit() {
    this.editFormTarget.classList.toggle('tw-hidden');
    this.commentBodyTarget.classList.toggle('tw-hidden');
  }
}
