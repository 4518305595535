import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $('#inputPassword').on('input', () => {
      const message = $('#password-message');
      const icon = $('#password-icon');
      const strength = $('#password-strength');
      const user_input = $('#inputPassword');
      const password = user_input.val();

      const password_info = this.check_password_strength(password);

      if (password.length > 0) {
        message.css('display', 'block');
        icon.removeClass('d-none');
      } else {
        message.css('display', 'none');
        icon.addClass('d-none');
      }

      let tooltip_text = 'Adicione: \n';
      tooltip_text += password_info.strength.hasMinDigits ? '' : '- Seis dígitos\n';
      tooltip_text += password_info.strength.hasNumber ? '' : '- Número\n';
      tooltip_text += password_info.strength.hasUpperCase ? '' : '- Letra maiúscula\n';
      tooltip_text += password_info.strength.hasLowerCase ? '' : '- Letra minúscula\n';

      switch (password_info.diversity) {
        case 0:
          user_input.css('border-color', '#ced4da');
          break;
        case 1:
          strength.text('muito fraca');
          user_input.css('border-color', '#DC4C64');
          message.css('color', '#dc4c64');
          icon.css('color', '#dc4c64');
          break;
        case 2:
          strength.text('fraca');
          user_input.css('border-color', '#DC4C64');
          message.css('color', '#dc4c64');
          icon.css('color', '#dc4c64');
          break;
        case 3:
          strength.text('média');
          user_input.css('border-color', '#E4A11B');
          message.css('color', '#E4A11B');
          icon.css('color', '#E4A11B');
          break;
        case 4:
          strength.text('forte');
          user_input.css('border-color', '#14A44D');
          message.css('color', '#14A44D');
          icon.css('color', '#14A44D');
          tooltip_text = 'Sua senha está forte!';
          break;
      }

      icon.attr('title', tooltip_text);
    });
  }

  check_password_strength(password) {
    let hasLowerCase = /(?=.*[a-z])/.test(password);
    let hasUpperCase = /(?=.*[A-Z])/.test(password);
    let hasNumber = /(?=.*\d)/.test(password);
    let hasMinDigits = /(?=.{6,})/.test(password);

    const diversity = [hasLowerCase, hasUpperCase, hasNumber, hasMinDigits].filter(Boolean).length;
    const isValid = diversity === 4;

    return {
      isValid,
      diversity,
      strength: {
        hasLowerCase,
        hasUpperCase,
        hasNumber,
        hasMinDigits,
      },
    };
  }
}
