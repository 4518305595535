// Copy from: https://github.com/ElMassimo/stimulus-vite-helpers/blob/main/src/index.ts (To make a monkey patch)
const CONTROLLER_FILENAME_REGEX =
  /^(?:.*?(?:controllers|components)\/|\.?\.\/)?(.+)(?:[/_-]controller\..+?)$/;

export function registerControllers(application, controllerModules) {
  application.load(definitionsFromGlob(controllerModules));
}

function definitionsFromGlob(controllerModules) {
  return Object.entries(controllerModules)
    .map(definitionFromEntry)
    .filter((value) => value);
}

function definitionFromEntry([name, controllerModule]) {
  const identifier = identifierForGlobKey(name);
  const controllerConstructor = controllerModule.default || controllerModule;
  if (identifier && typeof controllerConstructor === 'function') {
    return { identifier, controllerConstructor };
  }
}

function identifierForGlobKey(key) {
  const logicalName = (key.match(CONTROLLER_FILENAME_REGEX) || [])[1];
  if (logicalName) {
    return (
      logicalName.replace(/_/g, '-').replace(/\//g, '--') +
      (key.endsWith('_controller.js') ? '' : '--controller') // TODO: This is a hack to make the identifier work with our other conventions (--controller in the end of stimulus controller from componentes)
    );
  }
}
