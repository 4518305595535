import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['inputField', 'showEye', 'hiddenEye'];

  hidden() {
    $('.show-eye').toggleClass('d-none');
    $('.hidden-eye').toggleClass('d-none');

    if (this.inputFieldTarget.type === 'password') {
      this.inputFieldTarget.type = 'text';
    } else {
      this.inputFieldTarget.type = 'password';
    }
  }
}
