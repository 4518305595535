import { Controller as BaseController } from '@hotwired/stimulus';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { percentFormatter } from '../../../../javascript/modules/chartjs_utils';

export default class Controller extends BaseController {
  static values = { presencial: Number, online: Number };

  connect() {
    var ctx = document.getElementById('presencial-or-online-graph');
    new Chart(ctx, {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [this.presencialValue, this.onlineValue],
            backgroundColor: ['#9BC0C6', '#E06C9B'],
            borderWidth: 0,
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            formatter: percentFormatter,
            labels: {
              name: {
                color: ['#FFFFFF'],
                offset: 10,
                font: {
                  size: '16px',
                },
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }
}
