import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

// Connects to data-controller="login"
export default class extends Controller {
  connect() {
    //Add a JQuery click event handler onto our checkbox.
    $('#agreed_to_terms').click(function () {
      //If the checkbox is checked.
      if ($(this).is(':checked')) {
        //Enable the submit button.
        $('#submit_button').attr('disabled', false);
      } else {
        //If it is not checked, disable the button.
        $('#submit_button').attr('disabled', true);
      }
    });
  }

  agreeAndLogin() {
    Cookies.set('allow_cookies', 'yes', {
      expires: 365,
    });
    let submit_data = $('#loginForm').serialize();
    submit_data += '&agreed_to_terms=true';
    $.ajax({
      url: 'login',
      type: 'post',
      data: submit_data,
      dataType: 'json',
      success: () => {
        window.location.href = '/users/dashboard';
      },
      error: () => {
        window.location.href = '/auth/login/';
      },
    });
  }
}
