import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = { data: Object };

  connect() {
    // Handles table
    $('a[data-toggle="tab"]').on('click', function (e) {
      const table = $('#alternative-table');
      let target = $(e.target).attr('href'); // activated tab
      switch (target) {
        case '#alternative-tab':
          table.removeClass('percent result');
          table.addClass('alternative');
          break;
        case '#percentage-tab':
          table.removeClass('alternative result');
          table.addClass('percent');
          break;
        default:
          table.removeClass('alternative percent');
          table.addClass('result');
          break;
      }
    });

    // Bootstrap table
    const isMobile = screen.width < 640;
    const table = $('#alternative-table');
    if (isMobile) {
      table.bootstrapTable('destroy').bootstrapTable({
        stickyHeader: false,
        classes: 'table table-bordered',
      });
    } else {
      table.bootstrapTable('destroy').bootstrapTable({
        stickyHeader: false,
        classes: 'table table-bordered',
        fixedColumns: 'true',
        fixedNumber: 2,
      });
    }
    table.addClass('result');
  }
}
