import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const checkbox = document.getElementById('display_parent');
    const parentInfo = document.querySelector('.parent-info');

    checkbox.addEventListener('click', () => this.showHiddenDiv(checkbox, parentInfo));
    this.showHiddenDiv(checkbox, parentInfo);
  }

  showHiddenDiv(checkbox, parentInfo) {
    if (checkbox.checked) {
      parentInfo.style['display'] = 'block';
    } else {
      parentInfo.style['display'] = 'none';
    }
  }
}
