import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = { ability: Number };

  connect() {
    $('#abilityReportModal').on('shown.bs.modal', (e) => {
      let abilityId = $(e.relatedTarget).data('id');
      // Icon Load
      let reportModalSpinner = $('#abilityReportModalSpinner');
      reportModalSpinner.removeClass('d-none');

      // Request to ability
      $.ajax({
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        type: 'get',
        url: `/api/abilities/${abilityId}`,
        success: (r) => {
          const studentSupportMaterial = document.getElementById('ability-student-support');
          if (r.student_support_material) {
            studentSupportMaterial.href = this.support_material_link(r.id);
            this.enableLink(studentSupportMaterial);
          } else {
            studentSupportMaterial.href = '';
            this.disableLink(studentSupportMaterial);
          }

          const teacherSupportMaterial = document.getElementById('ability-teacher-support');
          if (r.teacher_support_material) {
            teacherSupportMaterial.href = this.support_material_link(r.id);
            this.enableLink(teacherSupportMaterial);
          } else {
            teacherSupportMaterial.href = '';
            this.disableLink(teacherSupportMaterial);
          }

          document.getElementById('ability-year-description').innerHTML = r.knowledge_field;
          document.getElementById('ability-modal-description').innerHTML = r.description;
          document.getElementById('ability-name').innerHTML = r.name;
          document.getElementById('ability-theme-unity').innerHTML = r.theme_unity;
          document.getElementById('ability-school-subject').innerHTML = r.school_subject_id;
          reportModalSpinner.addClass('d-none');
        },
      });
    });
  }

  disableLink(link) {
    // 1. Add isDisabled class to parent span
    link.parentElement.classList.add('isDisabled');
    // 2. Store href so we can add it later
    link.setAttribute('data-href', link.href);
    // 3. Remove href
    link.href = '';
    // 4. Set aria-disabled to 'true'
    link.setAttribute('aria-disabled', 'true');
  }

  enableLink(link) {
    // 1. Remove 'isDisabled' class from parent span
    link.parentElement.classList.remove('isDisabled');
    // 2. Set href
    link.href = link.getAttribute('data-href');
    // 3. Remove 'aria-disabled', better than setting to false
    link.removeAttribute('aria-disabled');
  }

  support_material_link(id, student_material = true) {
    let url = `ability/show_support_material/${id}`;
    if (student_material) {
      url += '?sp=true';
    }
    return url;
  }
}
