import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['forms'];

  toggle() {
    $('.filter-drawer').toggleClass('open close');
    $('.filter-drawer-background').toggleClass('d-none');

    const drawer = document.querySelector('.filter-drawer');
    const hasClosed = drawer.classList.contains('close');

    if (hasClosed) {
      $('.filter-drawer').css('right', '-360px');
      setTimeout(() => {
        $('.filter-drawer').addClass('d-none');
      }, 200);
    } else {
      $('.filter-drawer').css('right', '-360px');
      $('.filter-drawer').removeClass('d-none');
      setTimeout(() => {
        $('.filter-drawer').css('right', '0px');
      }, 200);
    }
  }

  submit() {
    const forms = this.formsTarget.getElementsByTagName('form');
    if (forms.length !== 0) {
      forms[0].requestSubmit();
    }
    this.toggle();
  }

  clear() {
    $('.select2-hidden-accessible').val(null).trigger('change');
    document.querySelectorAll('input').forEach(function (input) {
      input.value = '';
    });
  }
}
