import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  connect() {
    const cookiesBar = document.getElementById('cookies-bar');

    if (!cookiesBar) {
      return;
    }

    cookiesBar
      .querySelector('.accept')
      .addEventListener('click', () => this.allowCookies(true, cookiesBar));
  }

  allowCookies(allow, cookiesBar) {
    if (allow) {
      Cookies.set('allow_cookies', 'yes', {
        expires: 365,
      });
    } else {
      Cookies.set('allow_cookies', 'no', {
        expires: 365,
      });
    }

    cookiesBar.classList.add('hidden');
  }
}
