import { Controller } from '@hotwired/stimulus';
import alertMessage from '../modules/error';
import consumer from '../channels/consumer';

// Connects to data-controller="report"
export default class extends Controller {
  static values = {
    entityId: Number,
    examId: Number,
    type: String,
    url: String,
    user: Number,
    hideUpdateMessage: Boolean,
  };

  connect() {
    this.renderReport();
    document.addEventListener('turbo:before-cache', this.resetFilters);
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'ReportChannel',
        user_id: this.userValue,
        entity_id: this.entityIdValue,
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      },
    );
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.resetFilters);
    this.resetFilters();
  }

  _connected() {
    console.log('Connect');
  }

  _disconnected() {
    console.log('Disconnect');
  }

  _received(data) {
    this.displayUpdateNotification(data);
  }

  renderReport(data = null) {
    $.ajax({
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data: data,
      url: `/reports/exam_report_data/exam/${this.examIdValue}/entity/${this.entityIdValue}/type/${this.typeValue}/js`,
      remote: true,
      dataType: 'script',
      type: 'get',
      success: () => {
        let divElement = document.querySelector('#filter-report');
        divElement.innerHTML = 'Filtrar';

        if (data && data.refresh) {
          alertMessage(
            'O relatório foi enviado para atualização, o que pode demandar alguns instantes, sendo que em casos de relatórios extensos, a atualização pode se estender por algumas dezenas de minutos.',
            'notice',
          );
        }
      },
      error: () => {
        let divElement = document.querySelector('#filter-report');
        divElement.innerHTML = 'Filtrar';

        alertMessage(
          'Tente atualizar o relatório utilizando o ícone no canto superior direito',
          'warning',
        );
      },
    });
  }

  refresh() {
    this.renderReport({ refresh: true });
  }

  filterReport(event) {
    event.preventDefault();

    let divElement = document.querySelector('#filter-report');
    divElement.innerHTML =
      '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>';

    let selected_ids = this.selectIds(event.params.type);
    let method_filter = $('#correction_method').val();
    if ($(`#${event.params.type}`).val().length > 0) {
      let params = new URLSearchParams({
        correction_method: method_filter,
        selected_ids: JSON.stringify(selected_ids),
        filter: 'true',
      }).toString();
      this.renderReport(params);
    } else {
      this.selectRequired();
    }
  }

  filterPedagogicalReport() {
    let divElement = document.querySelector('#filter-report');
    divElement.innerHTML =
      '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>';

    const method_filter = $('#correction_method').val();
    const county_filter = $('#counties_select').val();
    const school_filter = $('#schools_select').val();
    const school_class_filter = $('#school_classes_select').val();
    const params = new URLSearchParams({
      correction_method: method_filter,
      counties_ids: JSON.stringify(county_filter),
      schools_ids: JSON.stringify(school_filter),
      school_classes_ids: JSON.stringify(school_class_filter),
      filter: 'true',
    }).toString();
    this.renderReport(params);
  }

  resetFilters() {
    $('.selectpicker').selectpicker('destroy').addClass('selectpicker');
  }

  selectIds(type) {
    if (type === 'classes') {
      return $('#classes').val();
    } else {
      return $('#schools').val();
    }
  }

  selectRequired() {
    $('#popoverContainer').popover('show');
    setTimeout(() => {
      $('#popoverContainer').popover('hide');
    }, 2000);
  }

  refreshFilters(type) {
    if (type === 'scheduled_exam') {
      return;
    } else if (type === 'school_classes') {
      $('#classes').find('option').prop('selected', true).parent('select').selectpicker('refresh');
    } else if (type === 'pedagogical_report') {
      $('#counties_select')
        .find('option')
        .prop('selected', true)
        .parent('select')
        .selectpicker('refresh');
      $('#schools_select')
        .find('option')
        .prop('selected', true)
        .parent('select')
        .selectpicker('refresh');
      $('#school_classes_select')
        .find('option')
        .prop('selected', true)
        .parent('select')
        .selectpicker('refresh');
    } else if (type === 'schools') {
      $('#schools').find('option').prop('selected', true).parent('select').selectpicker('refresh');
    }
    $('#correction_method').selectpicker('val', 'TRI'); // Reports::DEFAULT_SITUATION_TYPE
  }

  displayUpdateNotification(data) {
    if (data['status'] == 'updating') {
      console.log('Relatório está sendo atualizado');
      if (!this.hideUpdateMessageValue) {
        alertMessage(
          'O relatório está em processo de atualização, o que pode levar alguns momentos.',
          'notice',
        );
      }
    } else {
      this.refreshFilters(this.typeValue);

      console.log('Relatório atualizado');
      $(`#${data['div_id']}`).text(`Atualizado em: ${data['updated_at']}`);
      $(`#${data['body_id']}`).html(data['html']);
      if (data['message'] && !this.hideUpdateMessageValue) {
        alertMessage('Relatório atualizado com sucesso', 'success');
      }
    }
  }

  exportExcelReport() {
    const correction_method = document.querySelector('.correction_method select').value;
    if (this.typeValue === 'school_classes') {
      window.open(
        `/reports/school/${this.entityIdValue}/school_classes/${this.examIdValue}.xlsx?correction_method=${correction_method}`,
        '_blank',
      );
    } else if (this.typeValue === 'schools') {
      window.open(
        `/reports/county/${this.entityIdValue}/schools/${this.examIdValue}.xlsx?correction_method=${correction_method}`,
        '_blank',
      );
    } else if (this.typeValue === 'pedagogical_report') {
      window.open(
        `/reports/pedagogical_report/${this.entityIdValue}.xlsx?correction_method=${correction_method}`,
        '_blank',
      );
    } else if (this.typeValue === 'pedagogical_report_v4') {
      window.open(
        `/reports/v4/pedagogical_report/${this.entityIdValue}.xlsx?correction_method=${correction_method}`,
        '_blank',
      );
    }
  }
}
