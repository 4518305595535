import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = { missing: Number, present: Number };

  connect() {
    const presentPercentage = (
      (this.presentValue / (this.missingValue + this.presentValue)) *
      100
    ).toFixed(2);

    const labelPlugin = {
      id: 'labelPlugin',
      beforeDraw(chart) {
        const { ctx } = chart;
        ctx.save();
        ctx.fillStyle = 'black';
        ctx.font = '28px sans-serif';
        ctx.fillText(`${presentPercentage}%`, 87, 150);
      },
    };

    let ctx = document.getElementById('studentsParticipationChart');
    new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [this.missingValue, this.presentValue],
            label: 'Online',
            backgroundColor: ['#C9C9C9', '#48ACBB'],
            rotation: 310,
            cutout: '65%',
          },
        ],
      },
      options: {
        responsive: true,
      },
      plugins: [labelPlugin],
    });
  }
}
