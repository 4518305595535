import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['exportContent', 'spinner', 'hideContent'];
  static values = {
    fileName: { type: String, default: 'word-content.docx' },
  };

  async exam2Word() {
    const spinner = this.spinnerTarget;
    if (spinner) {
      spinner.classList.remove('d-none');
    }

    const images = this.exportContentTarget.getElementsByTagName('img');
    let count = images.length;
    for (let i = 0; i < count; i++) {
      let newSource = await this.toBase64(images[i].src).catch((e) => console.error(e));
      if (newSource) {
        images[i].src = newSource;
      }
    }

    if (spinner) {
      spinner.classList.add('d-none');
    }

    await this.export2Word();
  }

  // Utils methods
  export2Word() {
    let preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    let postHtml = '</body></html>';

    // Set correct alternative background
    let correctAlternatives = document.querySelectorAll('.correct-alternative');
    let oldBackground = [];
    correctAlternatives.forEach((correct) => {
      oldBackground.push(correct.style.backgroundColor);
      correct.style.backgroundColor = '#daeef1';
    });

    let alternativeLetters = document.querySelectorAll('.alternative-letter');
    alternativeLetters.forEach((letter) => {
      letter.insertAdjacentHTML('beforeend', '<span class="separator">)</span>');
    });

    const elementHtml = this.exportContentTarget;

    // Hide specicies sessions of page
    const hideElements = this.hideContentTargets;
    [...hideElements].forEach((hideElement) => elementHtml.removeChild(hideElement));

    let innerHtml = elementHtml.innerHTML;
    let html = preHtml + innerHtml + postHtml;

    let blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    let url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    const filename = this.fileNameValue;

    // Create download link element
    let downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);

    // Reset background
    correctAlternatives.forEach((correct, index) => {
      correct.style.backgroundColor = oldBackground[index];
    });

    document.querySelectorAll('.separator').forEach((separator) => {
      separator.remove();
    });
  }

  async toBase64(source) {
    return new Promise(async (resolve, reject) => {
      try {
        if (source.slice(0, 5) === 'http:' && window.location.host !== 'localhost:3000') {
          source = source.slice(0, 4) + 's' + source.slice(4);
        }
        const reader = new FileReader();
        const r = await fetch(source);
        const blob = await r.blob();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } catch (e) {
        reject(e);
      }
    });
  }
}
