import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  connect() {
    this.step = 0;
    this.changeStep(this.step);
  }

  next() {
    if (this.step < 3) {
      this.step++;
      this.changeStep(this.step);
    }
  }

  previous() {
    if (this.step > 0) {
      this.step--;
      this.changeStep(this.step);
    }
  }

  change(event) {
    this.changeStep(parseInt(event.params.step));
  }

  changeStep(stepNumber) {
    const steps = document.getElementsByClassName('step');
    const stepsArray = Array.from(steps);

    let nextStep = false;
    stepsArray.forEach((step, i) => {
      if (step.classList.contains('current') && i < stepNumber && !this.validFields())
        nextStep = true;
    });

    if (nextStep) return;

    stepsArray.forEach((step, i) => {
      if (stepNumber < i) {
        step.className = 'step';
      } else if (stepNumber == i) {
        step.className = 'step current';
      } else {
        step.className = 'step disabled';
      }
    });
  }

  validFields() {
    let nameInput = document.querySelector('#exams_model_name');
    let fileInput = document.querySelector('#upload-files');
    let correctQuestions = document.querySelectorAll('.nested-fields');
    let stepTwo = document.querySelector('.step-two');
    let stepThree = document.querySelector('.step-three');

    if (stepTwo && !stepTwo.classList.contains('d-none') && !nameInput.checkValidity()) {
      return false;
    } else if (stepThree && !stepThree.classList.contains('d-none')) {
      if (fileInput && !fileInput.checkValidity()) return false;
      if (correctQuestions.length > 0) {
        let validAnswers = true;
        correctQuestions.forEach((element) => {
          if (!element.children.item(2).querySelector('select').checkValidity())
            validAnswers = false;
        });

        return validAnswers;
      }
    }
    return true;
  }
}
