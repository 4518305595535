import { Controller } from '@hotwired/stimulus';
import alertMessage from '../modules/error';

export default class extends Controller {
  static values = {
    studentData: Object,
  };

  connect() {
    if (Object.keys(this.studentDataValue).length !== 0) {
      let chartStatus = Chart.getChart('studentChart');
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      const links = this.studentDataValue.links;
      const labels = this.studentDataValue.dates.map((date, i) => [
        date,
        this.studentDataValue.situations[i],
      ]);
      const student_mean =
        this.studentDataValue.scores.length > 0
          ? this.studentDataValue.scores.reduce((a, b) => a + b, 0) /
            this.studentDataValue.scores.length
          : 0;

      let ctx = document.getElementById('studentChart').getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              fill: false,
              label: 'Desempenho do Aluno',
              data: this.studentDataValue.scores,
              tooltip: this.studentDataValue.exam_names,
              backgroundColor: ['rgba(75, 192, 192, 0.2)'],
              borderColor: ['rgba(75, 192, 192, 1)'],
              borderWidth: 4,
            },
            {
              fill: false,
              label: 'Média do Aluno',
              data: Array(this.studentDataValue.scores.length).fill(student_mean),
              pointRadius: 0,
              tooltip: 0,
              backgroundColor: ['#418493'],
              borderColor: ['#418493'],
              borderWidth: 4,
            },
          ],
        },
        options: {
          hover: {
            intersect: false,
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: function (context) {
                  let current = context[0];
                  return current.dataset.tooltip[current.dataIndex];
                },
              },
            },
          },
          elements: {
            line: {
              tension: 0.5,
            },
          },
          onClick: function (evt, item) {
            window.open(links[item[0].index], '_blank');
          },
          scales: {
            y: {
              min: 0,
              max: 10,
            },
          },
          mantainAspectRatio: false,
        },
      });
    }
  }

  // TODO: Create a stimulus controller for this method
  copyLinkText() {
    console.log('Texto está sendo clicado!');
    let copyText = document.getElementById('link-input').value;

    navigator.clipboard.writeText(copyText).then(
      () => {
        alertMessage('Link copiado para a área de transferência', 'success');
      },
      () => {
        alertMessage('Erro ao copiar link', 'error');
      },
    );
  }
}
