import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  connect() {
    setTimeout(this.close, 5000);
  }

  close() {
    let toast = $('.toast-div');
    toast.css('transition', '.4s');
    toast.css('opacity', '0');
    setTimeout(function () {
      toast.css('display', 'none');
    }, 400);
  }
}
