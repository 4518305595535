import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = {
    examid: Number,
    itemid: Number,
    abilities: Array,
    itemColor: String,
  };

  abilities_with_exam() {
    if (this.abilitiesValue.length > 0) {
      $.ajax({
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        type: 'get',
        dataType: 'script',
        url: `/api/abilities_with_exam`,
        data: {
          ids: this.abilitiesValue,
          exam_id: this.examidValue,
          item_color: this.itemColorValue,
          div_id: 'critical-items-description',
        },
      });
    }
  }

  handle_click() {
    // Removes class from old element
    $('.critical-item-selected').removeClass('critical-item-selected');

    // Puts class in current element
    const element = '#critical-item-' + this.itemidValue;
    $(element).addClass('critical-item-selected');

    this.abilities_with_exam();
  }
}
