import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { counter: Number, action: String };
  static targets = ['template', 'area'];

  // Adding/Removing Questions

  addQuestion(event) {
    event.preventDefault();
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());

    this.counterValue++;

    // Adding new Question
    this.areaTarget.insertAdjacentHTML('beforeend', content);
    this.areaTarget.lastElementChild.children[0].querySelector('input').value = this.counterValue;

    const uniqueModalId = Date.now() + Math.floor(Math.random() * 10000);
    this.areaTarget.lastElementChild
      .querySelector('.modal.fade')
      .setAttribute('id', `modal-${uniqueModalId}`);
    this.areaTarget.lastElementChild
      .querySelector('.icon-container.edit')
      .setAttribute('data-target', `#modal-${uniqueModalId}`);

    // Adding new Question in Step4 (Review)
    if (this.actionValue == 'new') {
      const stepFour = document.getElementsByClassName('step-four');
      const stepFourArea = stepFour[0].getElementsByClassName('fields')[0];

      const content = document
        .querySelector('template')
        .innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
      stepFourArea.insertAdjacentHTML('beforeend', content);

      stepFourArea.lastElementChild.children[0].querySelector('input').value = this.counterValue;
      stepFour[0].getElementsByClassName(
        'questions-counter',
      )[0].textContent = `${this.counterValue} Questões inseridas`;

      // Remove Modal
      stepFourArea.lastElementChild.querySelector('.modal').remove();

      // Disable all inputs
      var inputsAndSelects = stepFourArea.querySelectorAll('input, select');
      for (var i = 0; i < inputsAndSelects.length; i++) {
        var element = inputsAndSelects[i];
        element.disabled = true;
      }

      // Remove Delete Button
      const deleteButton =
        stepFour[0].getElementsByClassName('delete-question-button')[0].parentElement;
      deleteButton.previousElementSibling.classList.replace('col-2', 'col-3');
      deleteButton.remove();
    }

    this.updateCounter(this.areaTarget.getElementsByClassName('nested-fields'));

    if (this.actionValue == 'new') {
      this.updateCounter(
        document.getElementsByClassName('step-four')[0].getElementsByClassName('nested-fields'),
      );
    }
  }

  removeQuestion(event) {
    event.preventDefault();
    this.counterValue--;

    let wrapper = event.target.closest('.nested-fields');

    if (this.actionValue == 'new') {
      // Get the index of the question
      var index = Array.from(wrapper.parentElement.children).indexOf(wrapper);

      // Get the target question row in step4 and removing it
      const stepFour = document.getElementsByClassName('step-four');
      const stepFourArea = stepFour[0].getElementsByClassName('fields')[0];
      stepFourArea.children[index].remove();

      stepFour[0].getElementsByClassName(
        'questions-counter',
      )[0].textContent = `${this.counterValue} Questões inseridas`;
    }

    if (wrapper.dataset.new === 'true') {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = 'none';
    }

    this.updateCounter(this.areaTarget.getElementsByClassName('nested-fields'));
    this.updateCounter(
      document.getElementsByClassName('step-four')[0].getElementsByClassName('nested-fields'),
    );
  }

  updateCounter(rows) {
    let activeRows = Array.from(rows).filter((row) => this.filterArray(row));
    for (let i = 0; i < activeRows.length; i++) {
      activeRows[i].children[0].querySelector('input').value = i + 1;
    }
  }

  filterArray(row) {
    if (row.style.display === 'none') {
      row.children[0].firstElementChild.value = row.dataset.id;
      return false;
    } else {
      return true;
    }
  }

  validateScaleInput(e) {
    if (e.target.value > 100) {
      e.target.value = 100;
    } else if (e.target.value < 0) {
      e.target.value = 0;
    }
  }
}
