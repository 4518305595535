import { Controller } from '@hotwired/stimulus';
import { autocomplete } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';

// Connects to data-controller="report"
export default class extends Controller {
  static targets = ['searchBox'];
  static values = {
    type: String,
    id: String,
    url: String,
  };

  showSearchPanel() {
    this.searchBoxTarget.querySelector('button').click();
  }

  connect() {
    this.resources = [];
    const that = this;
    autocomplete({
      container: '#autocomplete',
      openOnFocus: true,
      detachedMediaQuery: '',
      getSources({ query }) {
        return [
          {
            sourceId: 'predictions',
            onSelect: ({ item }) => {
              window.location.href = item.url;
            },
            getItems() {
              return that.resources.filter(({ label }) =>
                label.toLowerCase().includes(query.toLowerCase()),
              );
            },
            getItemInputValue({ item }) {
              return item.label;
            },
            templates: {
              item({ item }) {
                return `${item.label}`;
              },
            },
          },
        ];
      },
    });
  }

  openModal(event) {
    fetch(event.params.url)
      .then((response) => response.json())
      .then((data) => {
        this.resources = data;
        this.showSearchPanel();
      });
  }
}
