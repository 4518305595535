import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  connect() {
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
      let target = $(e.target).data('target');

      if (target === '#both-situation') {
        $('#calibrated-situation').addClass('active show');
        $('#question-situation').addClass('active show');
      } else {
        $(target).addClass('active show').siblings('.tab-pane.active').removeClass('active show');
      }
    });
  }
}
