const virtualClassroomChangeTab = (tab, virtualClassroomId) => {
  if (tab === 'grades') {
    $.ajax({
      // grades_path(virtual_classroom_id)
      url: `/virtual_classrooms/grades/${virtualClassroomId}`,
      type: 'get',
      dataType: 'script',
    });
  }
  let queryParams = new URLSearchParams(window.location.search);
  queryParams.set('tab', tab);
  history.replaceState(null, null, '?' + queryParams.toString());
};

window.virtualClassroomChangeTab = virtualClassroomChangeTab;
