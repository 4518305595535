import { Controller as BaseController } from '@hotwired/stimulus';

const userRedirectLoginPath = (response) => {
  if (response.verify_is_on || response.verify_is_on === null) {
    if (response.query_string) {
      window.location.href = '/api/oauth/authorize/?' + response.query_string;
      return;
    }

    if (response.role_id === 1) {
      window.location.href = '/exams/models';
      return;
    }
    window.location.href = '/users/dashboard';
  } else {
    window.location.href = '/';
  }
};

export default class Controller extends BaseController {
  connect() {}

  login(event) {
    event.preventDefault();
    let submit_data = $('#loginForm').serialize();
    $.ajax({
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      url: '/auth/login',
      type: 'post',
      data: submit_data,
      dataType: 'json',
      success: function (response) {
        if (response.agreed_to_terms) {
          userRedirectLoginPath(response);
        } else {
          if (response.verify_is_on || response.verify_is_on === null) {
            // Show modal
            let modalTerms = $('#modalTerms');
            modalTerms.modal({ backdrop: 'static', keyboard: false });
            modalTerms.modal('show');
          } else {
            window.location.href = '/';
          }
        }
      },
      error: () => {
        const queryString = window.location.search;
        window.location.href = '/auth/login/' + queryString;
      },
    });
  }
}
