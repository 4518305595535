import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    const url = this.urlValue;
    $('#profile_picture_upload').on('click', () => {
      var canvas = $('#canvas'),
        context = canvas.get(0).getContext('2d'),
        $result = $('#result');

      canvas.width = window.innerWidth * 0.8;
      canvas.height = window.innerHeight * 0.8;

      $('#profile_photo_upload')
        .unbind()
        .on('click', () => {
          // flag to fix js multiple time send
          var canvas = $('#canvas');
          const context = canvas.get(0).getContext('2d');
          context.clearRect(0, 0, canvas.width, canvas.height);
        });
      $('#fileInput').on('change', function () {
        if (this.files && this.files[0]) {
          if (this.files[0].type.match(/^image\//)) {
            var reader = new FileReader();
            reader.onload = function (evt) {
              var img = new Image();
              img.onload = function () {
                context.canvas.height = img.height;
                context.canvas.width = img.width;
                context.drawImage(img, 0, 0);

                canvas.cropper({
                  aspectRatio: 1,
                });

                $('#RotateLeft').on('click', function () {
                  canvas.cropper('rotate', -90);
                });

                $('#RotateRight').on('click', function () {
                  canvas.cropper('rotate', 90);
                });

                $('#btnCrop')
                  .unbind()
                  .on('click touchstart', function () {
                    // Get a string base 64 data url
                    // alert("Atualizando imagem...\nNão saia da página, isso pode demorar.");
                    var croppedImageDataURL = canvas
                      .cropper('getCroppedCanvas')
                      .toDataURL('image/jpeg', 0.5);
                    $result.append($('<img>').attr('src', croppedImageDataURL));
                    // Send Ajax
                    $.ajax({
                      beforeSend: (xhr) => {
                        xhr.setRequestHeader(
                          'X-CSRF-Token',
                          $('meta[name="csrf-token"]').attr('content'),
                        );
                      },
                      url: url,
                      type: 'POST',
                      data: 'profile_picture=' + croppedImageDataURL,
                      dataType: 'application/x-www-form-urlencoded',
                      success: () => {
                        location.reload();
                      },
                      error: () => {
                        location.reload();
                      },
                    });
                  });
                $('#btnRestore').on('click', function () {
                  canvas.cropper('reset');
                  $result.empty();
                });
              };
              img.src = evt.target.result;
            };
            reader.readAsDataURL(this.files[0]);
          } else {
            alert('Tipo inválido, por gentileza, adicione uma imagem válida');
          }
        } else {
          alert('Nenhum arquivo selecionado');
        }
      });
    });
  }
}
