import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  handleFormSubmit() {
    const dismissTarget = this.formTarget.querySelector('[data-dismiss="modal"]');
    if (dismissTarget) {
      dismissTarget.click();
    }
  }
}
