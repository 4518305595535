import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['password', 'passwordConfirm', 'passwordMessage', 'formButton'];

  connect() {
    // Check if password target match passwordConfirm target
    this.passwordTarget.addEventListener('input', () => {
      this.checkPassword();
    });

    this.passwordConfirmTarget.addEventListener('input', () => {
      this.checkPassword();
    });
  }

  checkPassword() {
    const password = this.passwordTarget.value;
    const passwordConfirm = this.passwordConfirmTarget.value;

    if (password === passwordConfirm) {
      this.passwordMessageTarget.style.color = '#14A44D';
      this.passwordConfirmTarget.style.borderColor = '#14A44D';
      this.passwordMessageTarget.innerHTML = 'As senhas correspondem';
      this.formButtonTarget.disabled = false;
    } else {
      this.passwordMessageTarget.style.color = '#DC4C64';
      this.passwordConfirmTarget.style.borderColor = '#DC4C64';
      this.passwordMessageTarget.innerHTML = 'As senhas não correspondem';
      this.formButtonTarget.disabled = true;
    }
  }
}
