import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = {
    placeholder: { type: String, default: 'Escolha uma opção' },
    options: { type: Array, default: ['Sem opções'] },
    multiple: { type: Boolean, default: false },
    insideFilter: { type: Boolean, default: false },
    dropdownParent: { type: String, default: '' },
    action: { type: String, default: '' },
    searchable: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    ajaxUrl: { type: String, default: '' },
    loadOnType: { type: Boolean, default: false },
    selected: { type: Array, default: [] },
  };

  static targets = ['select'];

  connect() {
    $(this.selectTarget).select2({
      placeholder: this.placeholderValue,
      multiple: this.multipleValue,
      ...(this.clearableValue && { allowClear: true }),
      ...(this.insideFilterValue && { dropdownParent: $('.filter-drawer') }),
      ...(this.dropdownParentValue !== '' && { dropdownParent: $(this.dropdownParentValue) }),
      ...(!this.searchableValue && { minimumResultsForSearch: -1 }),
      ...this.ajaxConfig(),
    });

    if (this.ajaxUrlValue !== '' && !this.loadOnTypeValue) {
      this.asyncSelectOptions();
    }

    if (this.multipleValue) {
      if (this.selectedValue.length > 0) {
        $(this.selectTarget).val(this.selectedValue).trigger('change');
      }
    }

    // Turbo bugs that duplicated elements
    const containerChildren =
      this.selectTarget.parentElement.getElementsByClassName('select2-container');
    if (containerChildren.length > 1) {
      containerChildren[1].remove();
    }

    if (this.actionValue !== '') {
      $(this.selectTarget).on('select2:select', () => {
        let event = new Event('change', { bubbles: true }); // fire a native event
        this.selectTarget.dispatchEvent(event);
      });
    }

    if (this.actionValue !== '') {
      $(this.selectTarget).on('select2:unselect', () => {
        let event = new Event('change', { bubbles: true }); // fire a native event
        this.selectTarget.dispatchEvent(event);
      });
    }
  }

  disconnect() {
    if ($(this.selectTarget).data('select2')) {
      $(this.selectTarget).select2('destroy');
    }
  }

  checkValue() {
    this.selectTarget.on('select2:select', function () {
      const selectedBox = document.querySelector('.select2-selection--single');
      selectedBox.classList.add('select-box-answered');
    });
  }

  ajaxConfig() {
    if (this.ajaxUrlValue !== '' && this.loadOnTypeValue) {
      return {
        ajax: {
          url: this.ajaxUrlValue,
          dataType: 'json',
          delay: 250,
          data: (params) => {
            let data = {
              name: params.term,
            };

            // Adding search params to support filter by year in scheduled exams modal
            // This is a workaround maybe can bug some other page
            const searchParams = new URLSearchParams(window.location.search);
            for (let pair of searchParams.entries()) {
              data[pair[0]] = pair[1];
            }

            return data;
          },
          processResults: function (data) {
            return {
              results: data.map((item) => ({ id: item.id, text: item.name })),
            };
          },
          cache: true,
        },
      };
    }
  }

  asyncSelectOptions() {
    $.ajax({
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      url: this.ajaxUrlValue,
      type: 'get',
      dataType: 'json',
      success: (data) => {
        $(this.selectTarget).empty(); // Clear existing options

        data.forEach((option) => {
          var newOption = new Option(option.name, option.id, false, false);
          $(this.selectTarget).append(newOption);
        });

        $(this.selectTarget).trigger('change');

        if (this.selectedValue.length > 0) {
          $(this.selectTarget).val(this.selectedValue).trigger('change');
        }
      },
    });
  }
}
