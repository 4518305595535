import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  connect() {
    const container = document.getElementById('scrollable-section');
    container.addEventListener('wheel', function (e) {
      if (e.deltaY > 0) {
        container.scrollLeft += 50;
        e.preventDefault();
      } else {
        container.scrollLeft -= 50;
        e.preventDefault();
      }
    });
  }
}
