import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const subjectButton = this.element.querySelector('#add-button');

    const clickHandler = (event) => {
      event.preventDefault();
      this.addSubject();
    };

    subjectButton.addEventListener('click', clickHandler);
  }

  disconnect() {
    const subjectButton = this.element.querySelector('#add-button');
    subjectButton.removeEventListener('click', this.addSubject);
  }

  changeNameOnAdd() {
    const firstSubjectThirdCol = document.getElementById('subject-third-col');
    const thirdColInput = firstSubjectThirdCol.querySelector('#input-number');
    thirdColInput.name = '';

    const firstSubjectFourthCol = document.getElementById('subject-fourth-col');
    const fourthColInput = firstSubjectFourthCol.querySelector('#input-number');
    fourthColInput.name = '';

    const secondSubjectFirstCol = document.getElementById('subject-first-col');
    const firstColInput = secondSubjectFirstCol.querySelector('#input-number');
    firstColInput.name = 'exams_template[col3]';

    const secondSubjectSecondCol = document.getElementById('subject-second-col');
    const secondColInput = secondSubjectSecondCol.querySelector('#input-number');
    secondColInput.name = 'exams_template[col4]';
  }

  changeNameOnRemove() {
    const firstSubjectThirdCol = document.getElementById('subject-third-col');
    const thirdColInput = firstSubjectThirdCol.querySelector('#input-number');
    thirdColInput.name = 'exams_template[col3]';

    const firstSubjectFourthCol = document.getElementById('subject-fourth-col');
    const fourthColInput = firstSubjectFourthCol.querySelector('#input-number');
    fourthColInput.name = 'exams_template[col4]';

    const secondSubjectFirstCol = document.getElementById('subject-first-col');
    const firstColInput = secondSubjectFirstCol.querySelector('#input-number');
    firstColInput.name = '';

    const secondSubjectSecondCol = document.getElementById('subject-second-col');
    const secondColInput = secondSubjectSecondCol.querySelector('#input-number');
    secondColInput.name = '';
  }

  addSubject() {
    const secondSubject = document.getElementById('second-section');
    const subjectButton = document.getElementById('add-button');
    const oneSubjectCounters = document.getElementById('single-subject');

    this.changeNameOnAdd();
    secondSubject.classList.remove('d-none');
    secondSubject.classList.add('row', 'flex-row', 'flex-wrap');
    subjectButton.classList.add('w-0', 'h-0', 'd-none');
    oneSubjectCounters.classList.add('w-0', 'h-0', 'd-none');
  }

  removeSubject() {
    const secondSubject = document.getElementById('second-section');
    const subjectButton = document.getElementById('add-button');
    const oneSubjectCounters = document.getElementById('single-subject');

    this.changeNameOnRemove();
    secondSubject.classList.add('d-none');
    secondSubject.classList.remove('row', 'flex-row', 'flex-wrap');
    subjectButton.classList.remove('w-0', 'h-0', 'd-none');
    oneSubjectCounters.classList.remove('w-0', 'h-0', 'd-none');
  }
}
