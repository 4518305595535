import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['number'];
  connect() {}

  decrease() {
    this.updateQuantity(-1);
  }

  increase() {
    this.updateQuantity(1);
  }

  updateQuantity(value) {
    if (this.hasNumberTarget) {
      const currentQuantity = parseInt(this.numberTarget.value, 10) || 0;
      const newQuantity = currentQuantity + value;

      if (newQuantity >= 0) {
        this.numberTarget.value = newQuantity;
      }
    }
  }
}
