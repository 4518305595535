import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'template',
    'school',
    'county',
    'classroom',
    'virtual',
    'area',
    'select',
    'option',
  ];
  static values = { counter: Number };

  connect() {
    console.log('Hello, Stimulus!');
  }

  addRow(event) {
    event.preventDefault();
    let date = new Date().getTime();
    let content = this.templateTarget.innerHTML.replace(/333/g, `${date}`);
    this.areaTarget.insertAdjacentHTML('beforeend', content);
  }

  removeRow(event) {
    event.preventDefault();
    let proceed = confirm('Certeza que deseja eliminar essa linha?');
    if (proceed) {
      let wrapper = event.target.closest('.nested-fields');
      wrapper.remove();
    }
  }

  addEmptyOption(target) {
    target.innerHTML = '';
    const option = document.createElement('option');
    option.value = null;
    option.innerHTML = 'Escolha uma opção';
    target.appendChild(option);
  }

  updateDropdown(data, target) {
    this.addEmptyOption(target);
    data.forEach((school) => {
      const option = document.createElement('option');
      option.value = school.id;
      option.innerHTML = school.name;
      target.appendChild(option);
    });
  }

  selectCounty() {
    const element = this.countyTarget;
    const countyId = element.value;
    $.ajax({
      type: 'get',
      url: element.dataset.way + '.json',
      data: new URLSearchParams({ county_id: countyId }).toString(),
      success: (data) => {
        this.updateDropdown(data, this.schoolTarget);
      },
    });
  }

  selectSchool() {
    const element = this.schoolTarget;
    const schoolId = element.value;
    $.ajax({
      type: 'get',
      url: element.dataset.way + '.json',
      data: new URLSearchParams({ school_id: schoolId }).toString(),
      success: (data) => {
        this.updateDropdown(data, this.classroomTarget);
      },
    });
  }

  selectSchoolClass() {
    const element = this.classroomTarget;
    const classId = element.value;
    $.ajax({
      type: 'get',
      url: element.dataset.way + '.json',
      data: new URLSearchParams({ school_class_id: classId }).toString(),
      success: (data) => {
        this.updateDropdown(data.virtual_classrooms, this.virtualTarget);
        this.updateDropdown(data.students, this.selectTarget);
      },
    });
  }

  toggleSelect() {
    let selectWrapper = document.getElementById(`student-ids${this.optionTarget.dataset.index}`);
    let select = document.getElementById(`student-ids-select${this.optionTarget.dataset.index}`);
    if (this.optionTarget.value === 'Alunos específicos') {
      selectWrapper.classList.remove('d-none');
      select.disabled = false;
      select.classList.remove('d-none');
    } else {
      selectWrapper.classList.add('d-none');
      select.disabled = true;
      select.classList.add('d-none');
    }
  }
}
