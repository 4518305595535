import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = {
    sidebar: { type: Boolean, default: false },
  };

  connect() {
    if (this.sidebarValue) {
      $('[data-toggle="tooltip-true"]').tooltip({
        boundary: 'window',
        offset: -75,
        sanitize: false,
        template:
          '<div class="tooltip tooltip-link" role="tooltip"><div class="arrow"></div><div class="tooltip-inner tooltip-link-css"></div></div>',
      });
    } else {
      $('[data-toggle="tooltip-false"]').tooltip({
        boundary: 'window',
        offset: 20,
        sanitize: false,
        template:
          '<div class="tooltip tooltip-link" role="tooltip"><div class="arrow"></div><div class="tooltip-inner tooltip-link-css"></div></div>',
      });
    }
    //close tooltip with a click on the page

    $('body').on('click', function (e) {
      if (
        $(e.target).data('toggle') !== 'tooltip' &&
        $(e.target).parents('[data-toggle="tooltip-false"]').length === 0 &&
        $(e.target).parents('[data-toggle="tooltip-true"]').length === 0 &&
        $(e.target).parents('.tooltip.in').length === 0
      ) {
        $('[data-toggle="tooltip-false"]').tooltip('hide');
        $('[data-toggle="tooltip-true"]').tooltip('hide');
      }
    });
  }

  showTooltip(event) {
    //close the open ones and don't change the pages
    $('[data-toggle="tooltip-false"]').tooltip('hide');
    $('[data-toggle="tooltip-true"]').tooltip('hide');
    event.preventDefault();
  }
}
