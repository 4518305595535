import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['circle', 'time'];
  static values = { start: String, duration: Number, finish: String };

  connect() {
    this.timeTarget.textContent = '...';

    this.interval = setInterval(() => {
      const startTime = new Date(this.startValue);
      const currentTime = new Date();
      const finishTime = new Date(this.finishValue);
      const diffInMinutes = Math.floor((currentTime - startTime) / 60000);
      const newValue = this.calculateValue(diffInMinutes, this.durationValue);
      const startPlusDuration = new Date(startTime.getTime() + this.durationValue * 60000);
      let time = '';

      if (startPlusDuration.getTime() > finishTime) {
        time = this.newTimeDelayedActivity(currentTime, finishTime);
      } else {
        time = this.newTime(startTime, currentTime, this.durationValue);
      }

      if (time < 0) {
        window.location.reload();
      }

      this.updateStrokeDasharray(newValue);

      this.timeTarget.textContent = this.formatTime(time);
    }, 1000);
  }

  // Atividade que vai acabar com a duracao
  newTime(startTime, currentTime, total) {
    const differenceInSeconds = total * 60 - Math.floor((currentTime - startTime) / 1000);
    return differenceInSeconds;
  }

  //  Atividade que vai acabar com o prozo
  newTimeDelayedActivity(currentTime, finishTime) {
    const differenceInMilliseconds = finishTime.getTime() - currentTime.getTime();

    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

    return differenceInSeconds;
  }

  formatTime(timeInSeconds) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  disconnect() {
    clearInterval(this.interval);
  }

  updateStrokeDasharray(newValue) {
    this.circleTarget.style.strokeDashoffset = newValue;
  }

  calculateValue(mm, duration) {
    return Math.ceil(146 - (146 / duration) * mm);
  }
}
