import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {}

  chooseAll = () => {
    let checkboxes = document.getElementsByClassName('collection-checkbox');
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = true;
    }
  };
}
