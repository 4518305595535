import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = { belowBasic: Number, basic: Number, adequate: Number, advanced: Number };

  connect() {
    const ctx = document.getElementById('total-results-chart').getContext('2d');
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Abaixo', 'Básico', 'Adequado', 'Avançado'],
        datasets: [
          {
            data: [this.belowBasicValue, this.basicValue, this.adequateValue, this.advancedValue],
            backgroundColor: ['#D9534F', '#F2C671', '#87D187', '#5495CD'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        barPercentage: 0.6,
        scales: {
          y: {
            ticks: {
              precision: 0,
              font: {
                size: 15,
              },
            },
            grid: {
              borderDash: [8, 8],
            },
            beginAtZero: true,
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 15,
              },
            },
          },
        },
      },
    });
  }
}
