import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static values = {
    modalid: String,
    questionid: Number,
  };

  connect() {
    const element_id = '#' + this.modalidValue;
    $(element_id).on('show.bs.modal', () => {
      $.ajax({
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          xhr.setRequestHeader(
            'Accept',
            'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
          );
        },
        type: 'get',
        dataType: 'script',
        url: `/questions/description`,
        data: { id: this.questionidValue },
        success: function (response) {
          Turbo.renderStreamMessage(response);
        },
      });
    });
  }
}
