import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

// The modal needs to be a bootstrap modal
export default class extends Controller {
  static targets = ['modal'];

  connect() {
    $('#limitsWarningModal').modal('show');
  }

  hide(e) {
    e.preventDefault();
    $(this.modalTarget).modal('hide');
    Turbo.visit(e.target.href);
  }

  hide_and_submit() {
    $(this.modalTarget).modal('hide');
  }
}
