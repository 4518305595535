import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['exams'];

  updateSelectedExams(e) {
    let innerHTML = '';
    Array.from(e.target.selectedOptions).forEach(function (element) {
      console.log(element.label);
      innerHTML +=
        `<div class='exam-modal-badge' title="${element.label}">` + element.label + '</div>';
    });
    this.examsTarget.innerHTML = innerHTML;
  }
}
