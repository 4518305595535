const arrayToUrlParams = (variableName, array) => {
  if (array.length === 0) {
    return '';
  }
  if (array.length >= 2) {
    let params = '';
    array.forEach((item, index) => {
      const path = `${variableName}[]=${item}`;
      if (index === array.length - 1) {
        params += path;
      } else {
        params += path + '&';
      }
    });
    return params;
  } else {
    return `${variableName}=${array[0]}`;
  }
};

const toUrlParamsArray = (variableName, array) => {
  if (typeof array == 'string') {
    return `${variableName}=${array}&`;
  } else if (array.length === 1) {
    return `${variableName}[]=${array[0]}&`;
  } else {
    const arrayToUrl = arrayToUrlParams(variableName, array);
    if (arrayToUrl) {
      return arrayToUrlParams(variableName, array) + '&';
    }
  }
};

const listParamsToQueryUrl = (listParams) => {
  let url = '';
  listParams.forEach(function (value, i) {
    let urlParamsArray = toUrlParamsArray(listParams[i][0], listParams[i][1]);
    if (urlParamsArray) {
      url += urlParamsArray;
    }
  });
  return url;
};

const listParamsToQueryUrlQuestion = (listParams) => {
  let url = '';
  listParams.forEach(function (value, i) {
    if (i === 0) {
      url += '%5Bquestion_content%5D%5B' + listParams[i][0] + '%5D=' + listParams[i][1] + '&';
    }
    url += 'question%5B' + listParams[i][0] + '%5D=' + listParams[i][1] + '&';
  });
  return url;
};

window.listParamsToQueryUrl = listParamsToQueryUrl;
window.listParamsToQueryUrlQuestion = listParamsToQueryUrlQuestion;
window.arrayToUrlParams = arrayToUrlParams;
