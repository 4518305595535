import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['bookSelect', 'linkForm', 'bookForm'];
  static values = {
    initialType: { type: String, default: 'book' },
  };

  connect() {
    this.selectType();
    this.hideForm(this.initialTypeValue);
  }

  onChangeMediaType(event) {
    this.hideForm(event.target.value);
  }

  hideForm(type) {
    if (type == 'book') {
      $(this.linkFormTarget).hide();
      $(this.bookFormTarget).show();
    } else {
      $(this.linkFormTarget).show();
      $(this.bookFormTarget).hide();

      const label = this.linkFormTarget.getElementsByTagName('label')[0];
      if (type == 'video') {
        label.innerText = 'Link do YouTube*';
      } else {
        label.innerText = 'Tag do iframe*';
      }
    }
  }

  selectType() {
    const calameo = document.getElementById('calameo');
    const pdf = document.getElementById('pdf');
    if (this.bookSelectTarget.value === 'Calameo') {
      $(pdf).hide();
      $(calameo).show();
    } else {
      $(pdf).show();
      $(calameo).hide();
    }
  }
}
